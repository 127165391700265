<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        md="6">
        <div
          class="report-btn"
          @click="changeRoute('SalesReport')">
          รายงานการขาย
        </div>
      </v-col>
      <v-col
        v-if="role === 'administrator'"
        cols="12"
        md="6">
        <div
          class="report-btn"
          @click="changeRoute('VendorsSalesReport')">
          รายงานการขายของเวนเดอร์
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    user () {
      return this.$store.getters['User/user']
    },
    role () {
      return this.user?.role || 'alltotrips'
    }
  },
  methods: {
    changeRoute (to) {
      this.$router.push({ name: to })
    }
  }
}
</script>

<style scoped>
.report-btn {
  background-color: white;
  color: #026EAA;
  border: 1px solid #026EAA;
  border-radius: 8px;
  padding: 0.8rem 1.5rem;
  width: 100%;
  height: 5rem;
  font-size: 20px;
  font-weight: medium;
  cursor: pointer;
  display: flex;
  align-items: center;
}
</style>
